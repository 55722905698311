import React from "react";
import Options from "./Options";
import imageToBase64 from "../utils/helperFunction";
import CountrySelector from "./CountrySelector";
import { Controller } from "react-hook-form";

const DynamicQuestions = ({
    questions,
    handleChanger,
    handleBlur,
    handleFocus,
    handleInputFile,
    handleCheckboxChange,
    handleRadiobuttonChange,
    handleSelector,
    recordTicketsTypes,
    register,
    control,
    errors,
}) => {
    const questionsList =
        recordTicketsTypes[0].questions &&
        recordTicketsTypes[0].questions.map((question) => {
            if (
                question.type === "text" &&
                !question.name?.includes("Country")
            ) {
                return (
                    <label key={question.id}>
                        <p>
                            {question.name}{" "}
                            <span>{question.mandatory && "*"}</span>
                        </p>
                        <input
                            name={question.name}
                            type="text"
                            onChange={(e) => handleChanger(e, question.id)}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            required={question.mandatory}
                        />
                    </label>
                );
            } else if (
                question.type === "text" &&
                question.name?.includes("Country")
            ) {
                return (
                    <label key={question.id}>
                        <p>
                            {question?.name} <span>*</span>
                        </p>
                        {/* <select
              name="country"
              onChange={(e) => handleChanger(e, question.id)}
              required
            >
              <Options />
            </select> */}
                        <CountrySelector
                            control={control}
                            name={question?.name}
                            questionId={question.id}
                            handleSelector={handleSelector}
                            isMandatory={question.mandatory}
                        />
                        {console.log(errors, "#####")}
                        {errors[question?.name] && (
                            <div
                                style={{
                                    color: "#f00",
                                }}
                            >
                                {errors[question?.name].message}
                            </div>
                        )}
                    </label>
                );
            } else if (question.type === "image") {
                return (
                    <label key={question.id}>
                        <p>
                            {question.name} <span>*</span>
                        </p>
                        <input
                            name={question.name}
                            type="file"
                            onChange={async (e) => {
                                const file = e.target.files[0];
                                const base64Data = await imageToBase64(file);
                                console.log(base64Data, "base64Data");
                                handleInputFile(base64Data, question.id);
                            }}
                            accept="image/*"
                            required={question.mandatory}
                            style={{
                                paddingTop: "14px",
                            }}
                        />
                    </label>
                );
            } else if (question.type === "radiobutton") {
                return (
                    <Controller
                        control={control}
                        name={question.name}
                        rules={{
                            required: "this field is required",
                        }}
                        render={({ field }) => (
                            <div style={{ width: "100%", overflow: "hidden" }}>
                                <label style={{ minWidth: "100%" }}>
                                    <p>
                                        {question.name}{" "}
                                        <span>{question.mandatory && "*"}</span>
                                    </p>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        {question.options.map(
                                            (option, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        display: "flex",
                                                        marginTop: "2rem",
                                                        width: "40rem",
                                                        gap: "1rem",
                                                        maxWidth: "100%",
                                                    }}
                                                >
                                                    <input
                                                        name={question.name}
                                                        type="radio"
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleRadiobuttonChange(
                                                                e,
                                                                question.id
                                                            );
                                                        }}
                                                        onBlur={handleBlur}
                                                        onFocus={handleFocus}
                                                        value={option.id}
                                                        style={{
                                                            width: "1.5rem",
                                                        }}
                                                    />
                                                    <p>{option.name}</p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </label>
                                {errors[field.name] && (
                                    <div className="text-red-600 font-semibold ">
                                        {errors[field.name].message}
                                    </div>
                                )}
                            </div>
                        )}
                    />
                );
            } else if (question.type === "checkbox") {
                return (
                    <Controller
                        control={control}
                        name={question.name}
                        rules={
                            question.mandatory && {
                                required: "this field is required",
                            }
                        }
                        render={({ field: { onChange, value, name } }) => (
                            <div style={{ width: "100%", overflow: "hidden" }}>
                                <label style={{ minWidth: "100%" }}>
                                    <p>
                                        {question.name}{" "}
                                        <span>{question.mandatory && "*"}</span>
                                    </p>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        {question.options.map(
                                            (option, index) => (
                                                <div
                                                    key={index * 99}
                                                    style={{
                                                        display: "flex",
                                                        marginTop: "2rem",
                                                        width: "40rem",
                                                        gap: "1rem",
                                                        maxWidth: "100%",
                                                    }}
                                                >
                                                    <input
                                                        name={question.name}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            handleCheckboxChange(
                                                                e,
                                                                question.id,
                                                                `"${option.id}"`
                                                            );
                                                        }}
                                                        onBlur={handleBlur}
                                                        onFocus={handleFocus}
                                                        value={option.id}
                                                        style={{
                                                            width: "1.5rem",
                                                        }}
                                                    />
                                                    <p>{option.name}</p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </label>

                                {errors[name] && (
                                    <div
                                        style={{
                                            color: "#f00",
                                        }}
                                    >
                                        {errors[name].message}
                                    </div>
                                )}
                            </div>
                        )}
                    />
                );
            } else if (question.type === "session") {
                return (
                    <label key={question.id}>
                        <p>
                            {question?.name}{" "}
                            <span>{question.mandatory && "*"}</span>
                        </p>

                        <CountrySelector
                            questionId={question.id}
                            handleSelector={handleSelector}
                            control={control}
                            errors={errors}
                            name={question?.name}
                            isMandatory={question.mandatory}
                        />
                        {errors[question?.name] && (
                            <div
                                style={{
                                    color: "#f00",
                                }}
                            >
                                {errors[question?.name].message}
                            </div>
                        )}
                    </label>
                );
            } else {
                return null;
            }
        });
    return <>{questionsList}</>;
};

export default DynamicQuestions;
