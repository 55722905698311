export default function imageToBase64(file) {
  return new Promise((resolve, reject) => {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result;
      resolve(base64String);
    };
    reader.onerror = () => {
      reject(new Error("Error reading the file"));
    };
    reader.readAsDataURL(file); // Read the file as a data URL
  });
}
