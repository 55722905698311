// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
    overflow-x: hidden;
}
.App {
    width: 100%;
    background-color: black;
    padding: 50px;
    font-family: Arial, Helvetica, sans-serif;
}

header.logo {
    color: white;
    text-align: center;
    max-width: 100%;
    margin: 0 auto 50px;
}

header.logo h1 {
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
}

header.logo p {
    font-weight: 300;
    margin-top: 10px;
    line-height: 25px;
    font-size: 16px;
}

header.logo h2 {
    font-size: 30px;
    margin-top: 25px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

header.logo span {
    font-weight: bold;
    font-size: 12px;
}

button.disabled {
    cursor: not-allowed !important;
}

p.link {
    color: black;
    text-align: center;
}
p.link a {
    color: black;
    text-decoration: none;
    margin-top: 30px !important;
    color: blue;
}

@media screen and (max-width: 800px) {
    .App {
        padding: 50px 30px;
    }
}

@media screen and (max-width: 500px) {
    .App {
        padding: 50px 5px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,SAAS;IACT,sBAAsB;AAC1B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,uBAAuB;IACvB,aAAa;IACb,yCAAyC;AAC7C;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,yCAAyC;AAC7C;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,4DAA4D;AAChE;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,qBAAqB;IACrB,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["* {\n    padding: 0;\n    margin: 0;\n    box-sizing: border-box;\n}\nbody {\n    overflow-x: hidden;\n}\n.App {\n    width: 100%;\n    background-color: black;\n    padding: 50px;\n    font-family: Arial, Helvetica, sans-serif;\n}\n\nheader.logo {\n    color: white;\n    text-align: center;\n    max-width: 100%;\n    margin: 0 auto 50px;\n}\n\nheader.logo h1 {\n    font-weight: bolder;\n    font-family: Arial, Helvetica, sans-serif;\n}\n\nheader.logo p {\n    font-weight: 300;\n    margin-top: 10px;\n    line-height: 25px;\n    font-size: 16px;\n}\n\nheader.logo h2 {\n    font-size: 30px;\n    margin-top: 25px;\n    font-family: \"Segoe UI\", Tahoma, Geneva, Verdana, sans-serif;\n}\n\nheader.logo span {\n    font-weight: bold;\n    font-size: 12px;\n}\n\nbutton.disabled {\n    cursor: not-allowed !important;\n}\n\np.link {\n    color: black;\n    text-align: center;\n}\np.link a {\n    color: black;\n    text-decoration: none;\n    margin-top: 30px !important;\n    color: blue;\n}\n\n@media screen and (max-width: 800px) {\n    .App {\n        padding: 50px 30px;\n    }\n}\n\n@media screen and (max-width: 500px) {\n    .App {\n        padding: 50px 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
