import './App.css';
import Form from "./component/Form"

function App() {
  return (
    <div className="">
        <Form />
    </div>
  );
}

export default App;
