import React, { useState } from "react";

const Checkboxes = ({ questions, handleCheckboxChange, isChecked }) => {
  const [numberQuestion, setnumberQuestion] = useState(-1);
  return (
    questions &&
    questions.map((question, index) => {
      if (question.type === "checkbox") {
        return (
          <div key={index} className="interests">
            <p>
              {question.name}
              {question.mandatory === 1  && <span>*</span>}
            </p>
            {question.options.map((optopn) => {
              return (
                <label key={optopn.id}>
                  <input
                    type="checkbox"
                    value={optopn.id}
                    name={optopn.name}
                    onChange={(e) =>
                      handleCheckboxChange(e, question.id, optopn.id)
                    }
                  />
                  {optopn.name}
                </label>
              );
            })}
            {question.maxchoice != null && question.minchoice != null && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  display: "block",
                }}
              >
                You must choose at least one and a maximum of{" "}
                {question.maxchoice}{" "}
              </p>
            )}
          </div>
        );
      } else if (question.type === "session") {
        return (
          <div key={index} className="interests">
            <p>
              {question.name}
              {question.mandatory === 1  && <span>*</span>}
            </p>
            {question.session_options.map((optopn) => {
              return (
                <label key={optopn.id}>
                  <input
                    type="checkbox"
                    value={optopn.id}
                    name={optopn.title}
                    onChange={(e) =>
                      handleCheckboxChange(e, question.id, optopn.id)
                    }
                  />
                  {optopn.title}
                </label>
              );
            })}
          </div>
        );
      } else {
        return null;
      }
    })
  );
  // return (
  //     <div className='interests'>
  //         <p>Interested in / مهتم ب <span>*</span></p>
  //         {checkList}
  //         <p style={{color: "red", fontSize: "12px", display: isChecked ? "none" : "block" }}>At least one interest must be checked /يجب اختيار واحد علي الاقل</p>

  //     </div>
  // )
};

export default Checkboxes;
