import React, { useState, useMemo } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import countryList from "react-select-country-list";

function CountrySelector({
    questionId,
    handleSelector,
    control,
    name,
    isMandatory,
}) {
    const [value, setValue] = useState("");
    const options = useMemo(() => countryList().getData(), []);

    const changeHandler = (value) => {
        setValue(value);
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={
                isMandatory && {
                    required: "this field is required",
                }
            }
            render={({ field: { onChange, value, name } }) => (
                <Select
                    options={options}
                    value={value}
                    onChange={(e) => {
                        setValue(e);
                        handleSelector(e, questionId);
                        onChange(e);
                    }}
                />
            )}
        />
    );
}

export default CountrySelector;
