import React, { useState } from "react";

const Radiobutton = ({ questions, handleRadiobuttonChange }) => {
  return (
    questions &&
    questions.map((question, index) => {
      if (question.type === "radiobutton") {
        return (
          <div key={index} className="interests">
            <p>
              {question.name}
              <span>*</span>
            </p>
            {question.options.map((optopn) => {
              return (
                <label key={optopn.id}>
                  <input
                    type="radio"
                    value={optopn.id}
                    name={question.name}
                    onChange={(e) => handleRadiobuttonChange(e, question.id)}
                    required
                  />
                  {optopn.name}
                </label>
              );
            })}
            {/* <p
              style={{
                color: "red",
                fontSize: "12px",
                display: isChecked ? "none" : "block",
              }}
            >
              At least one interest must be checked /يجب اختيار واحد علي الاقل
            </p> */}
          </div>
        );
      } else {
        return null;
      }
    })
  );
  // return (
  //     <div className='interests'>
  //         <p>Interested in / مهتم ب <span>*</span></p>
  //         {checkList}
  //         <p style={{color: "red", fontSize: "12px", display: isChecked ? "none" : "block" }}>At least one interest must be checked /يجب اختيار واحد علي الاقل</p>

  //     </div>
  // )
};

export default Radiobutton;
